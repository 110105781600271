
export class Globals {
  public static originSearch = '';
  public static mapInstance;
  public static querySchool: Boolean = false;
  public static maxKmRange: Number = 5;
  public static userLocation: Object = {};
  public static schools: Array<object> = [];
  public static calendaryDate: Object = {};
  public static monthLabels: Object = {};
  public static weekDayLabels = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'];
}
